.chitalib-content {
	position: relative;
	flex: 1 auto;
	display: block;

	overflow-x: hidden;
	overflow-y: auto;
	z-index: 0;
}

@media all and (max-width: 800px) {
	.chitalib-content { min-width: 100vw; }
}