.chitalib-header {
	background-color: #fff;
	height: 3.5em;
	max-height: 3.5em;
	min-height: 3.5em;

	z-index: 3;
	box-shadow: 0 3px 3px -2px rgba(68,68,68,.5);
	
	display: flex;
	justify-content: space-between;
}

.chitalib-header-content {
	flex-grow: 1;
	line-height: 2.5rem;
	margin: 0 .25rem;
	display: flex;
    align-items: center;
}