body {
  margin: 0;
  /*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  */
  font-family: 'Montserrat', 'Rubik', sans-serif;
  overflow: hidden;
  position: relative;
}

code {
  /*
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  */
  font-family: 'Montserrat', 'Rubik', sans-serif;
}

a {
  all: unset;
  cursor: pointer;
  display: contents;
}

h1 {
  font-size: 1.6em;
  font-weight: 300;
}

h3 {
  font-weight: 500;
}

::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #f9f9f9;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #f9f9f9;
}
::-webkit-scrollbar-thumb:active {
  background: #f9f9f9;
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #ffffff;
}
::-webkit-scrollbar-track:active {
  background: #f9f9f9;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

hr {
  height: 1px;
  background-color: #e8e8e8;
  border: none;
  width: 100%;
}

p {
  line-height: 1.6em;
  font-weight: 300;
}