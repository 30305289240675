.chitalib-menu-toggle {
	height: 100%;
	width: 1.8rem;
	padding: 0 1rem;
	display: none;
}

.chitalib-menu {
	position: relative;	
	flex: 0 0 15vw;
	width: 15vw;
	background-color: #f6f6f6;
}

.chitalib-menu::after {
	content: '';
	position: absolute;
	right: 100%;
	top: 0;
	height: 100%;
	width: 100vw;
	background-color: #f6f6f6;
	display: block;
}

.chitalib-menu-shadower {
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color:rgba(255,255,255,0.5);
	z-index: 2;
}

@media all and (max-width: 800px) {
	.chitalib-menu {
		flex: 0 0 50vw;
		max-width: 50vw;
		margin-left: -50vw;
		transition: margin 175ms ease;
	}
	[dir=rtl] .chitalib-menu {
		margin-left: 0;
		margin-right: -50vw;
	}

	.chitalib-menu[data-toggled='true'] {
		margin-left: 0vw;
		margin-right: 0vw;
	}

	.chitalib-menu-toggle {
		display: block;
	}

	.chitalib-menu-shadower[data-toggled='true'] {
		transition: margin 175ms ease;
		display: block;
		left: 50vw;
	}

	[dir=rtl] .chitalib-menu-shadower {
		left: auto;
		right: 50vw;
	}

}